import React from "react";

import * as Styled from "./Header.styles";

// interface HeaderProps {}

function Header() {
  return (
    <Styled.Container>
      <Styled.LogoWrapperLink href="http://kruko.io" target="_blank">
        <svg
          width="132"
          height="47"
          viewBox="0 0 132 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M126.404 13.1911C129.791 16.523 131.543 19.2026 131.543 19.2026C131.543 19.2026 128.658 17.9059 124.291 17.2012C119.301 16.3958 112.377 16.3635 105.614 19.9228C98.5326 23.6498 92.4157 27.7625 86.764 31.5623C81.4936 35.1058 76.6276 38.3772 71.7619 40.8101C70.157 41.5456 68.161 42.4302 65.8983 43.2615C60.0374 45.4147 52.3876 47.2101 45.1126 45.1316C37.3845 42.9236 33.464 39.0234 27.1906 32.7824C25.2785 30.8802 23.1477 28.7605 20.6241 26.4051C16.2356 22.3092 12.7187 20.5504 9.83181 19.9703C6.58953 19.3188 4.14208 20.154 2.14754 20.8347C1.54597 21.04 0.985579 21.2312 0.457031 21.3633C2.32436 19.6127 5.38592 17.3515 9.05842 15.4898C12.9407 13.5218 17.5058 12.0002 22.0646 12.0001C30.1257 12 37.2155 17.9474 44.9282 24.9783C46.4682 24.4044 48.3319 23.6748 49.4061 23.2175C53.1175 21.6374 57.3762 19.3947 61.6786 16.3216C76.2368 5.92286 87.5799 0.476067 96.9709 0.476074C110.853 0.476085 120.837 7.71476 126.404 13.1911ZM48.5432 28.2469C52.0311 26.6303 60.3388 22.5893 64.1904 19.8381C78.7034 9.47173 89.0623 4.79757 96.9709 4.79758C106.262 4.79759 113.636 8.44804 118.929 12.4366C114.301 12.4275 108.93 13.2939 103.601 16.0987C96.3141 19.934 90.0364 24.1547 84.4179 27.9323L84.3528 27.976C79.6245 31.1551 75.4478 33.9568 71.3725 36.1444C69.7978 36.9897 66.3141 38.5123 66.0197 38.6407C61.0045 36.525 54.2463 33.1035 48.5432 28.2469ZM23.5727 23.2458C20.7157 20.5793 18.0605 18.6974 15.5597 17.4477C17.731 16.7417 19.9379 16.3216 22.0646 16.3216C25.2855 16.3215 28.5736 17.6199 32.3279 20.1675C36.028 22.6783 39.7611 26.1009 44.0231 30.0083L44.3532 30.3109C49.2589 34.8079 54.9668 38.1988 59.9264 40.6201C55.5516 41.7653 50.7759 42.2552 46.2998 40.9763C41.9907 39.7452 39.1175 37.9487 35.9782 35.2119C34.3464 33.7893 32.6663 32.1332 30.6374 30.1156L30.2327 29.713C28.324 27.8142 26.1485 25.6499 23.5727 23.2458Z"
            fill="white"
          />
        </svg>
      </Styled.LogoWrapperLink>
    </Styled.Container>
  );
}

export default Header;
