import React, { useState, useEffect } from "react";

import * as Styled from "./Video.styles";

interface VideoProps {
  src: string;
  mobileSrc: string;
}

const Video = ({ src, mobileSrc }: VideoProps) => {
  const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    const updateVideoSource = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 700) {
        setVideoSrc(mobileSrc);
      } else {
        setVideoSrc(src);
      }
    };

    updateVideoSource();

    window.addEventListener('resize', updateVideoSource);

    return () => {
      window.removeEventListener('resize', updateVideoSource);
    };
  }, [mobileSrc, src]);

  return (
      <Styled.Container>
        <Styled.Video src={videoSrc} muted autoPlay loop playsInline crossOrigin="" preload="auto">
        </Styled.Video>
      </Styled.Container>
  );
}

export default Video;
