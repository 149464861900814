import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
