import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
`;
