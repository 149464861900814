import styled from "styled-components";

export const Button = styled.a`
  background-color: #212121;
  display: inline-block;
  font-weight: 500;
  padding: 19px 27px;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
  transition: 0.1s background-color;

  &:hover {
    background-color: #FFA775;
  }
`;