import React from "react";

import * as Styled from "./Footer.styles";
import { Button } from "./Button.styles";

// interface FooterProps {}

function Footer() {
  return <Styled.Container>
    <Styled.Title>Innovation in every detail</Styled.Title>
    <Styled.Description>We help you push the boundaries of your imagination by creating unique designs tailored to your needs.</Styled.Description>
    <Button href="https://kruko.io" target="_blank" role="button">Check Kruko.io</Button>
  </Styled.Container>;
}

export default Footer;
