import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  padding-bottom: 80px;
  text-align: center;
  width: 100%;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
`;

export const Description = styled.p`
  font-size: 15px;
  max-width: 500px;
  line-height: 20px;
  margin-bottom: 40px;
  padding: 0 38px;
`;