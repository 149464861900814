import styled from "styled-components";
import { media } from "../styles/variables";

export const Container = styled.ul`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 71px;

  ${media.lessThan('sm')`
    display: none;
  `}
`;

export const Item = styled.li`
  height: 50px;
  width: 50px;
  margin-bottom: -4px;
`;

export const LinkIcon = styled.a`
  border-radius: 50%;
  background-color: #2d2d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  transition: background-color 0.1s;

  &:hover {
    background-color: #ffa775;

    svg {
      path {
        fill: #000;
      }
    }
  }
`;
