import React from "react";
import * as Styled from "./App.styles";
import Video from "./components/Video";
import Header from "./components/Header";
import SocialBar from "./components/SocialBar";
import Footer from "./components/Footer";

function App() {
  return (
    <Styled.Container>
      <Video src={`${process.env.PUBLIC_URL}/video/intro.mp4`} mobileSrc={`${process.env.PUBLIC_URL}/video/intro_mobile.mp4`} />
      <Header />
      <SocialBar />
      <Footer />
    </Styled.Container>
  );
}

export default App;