import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  padding-top: 80px;
  text-align: center;
  width: 100%;
`;

export const LogoWrapperLink = styled.a``;

export const LogoImg = styled.img``;
